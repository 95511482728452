import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <div className="overflowhidden story">
      <div id="triggerdisclaimer"></div>
      <div style={{ height: "100vh" }} id="disclaimer">
        <a href="https://yofrancisco.com">go back home {">>"}</a>
        <br />A simple proof of concept short story for testing animating
        websites
        <br />
        <br />
        Disclaimer: contains content that may be a little spoopy
        <br />~
        <br />
        Keep scrolling to read the story
        <br />
        \/
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p id="loader">Loading...</p>
      </div>
      {/* <!-- MOON --> */}
      <div id="triggersmoon"></div>
      <div id="pinsmoon" className="">
        <div className="smoon">
          <img src="/art/mooncirclesmaller.png" />
          <h1 className="title1">UNTITLED #1</h1>
          <h2 className="author">by frAnciscO mArtinez</h2>
        </div>
      </div>
      <div id="trigger_"></div>
      {/*  <!-- moon setting --> */}
      <div id="triggerms"></div>
      <div id="pinms" className="">
        <div className="sms">
          <img id="msmoon" src="/art/ms/moon.png" />
          <img id="msmountains" src="/art/ms/mountains.png" />
          <span id="beastwalk">
            <img id="msb1" src="/art/ms/thebeast1.png" />
            <img id="msb2" src="/art/ms/thebeast2.png" />
            <img id="msb3" src="/art/ms/thebeast3.png" />
            <img id="msb4" src="/art/ms/thebeast4.png" />
          </span>
          <img id="mstrees" src="/art/ms/trees.png" />
          <img id="msgrass" src="/art/ms/grass.png" />
          <img id="msbcloud_1" src="/art/ms/bcloud_1.png" />
          <img id="msbcloud_2" src="/art/ms/bcloud_2.png" />
          <img id="msbcloud_3" src="/art/ms/bcloud_3.png" />
          <img id="msbcloud_4" src="/art/ms/bcloud_4.png" />
          <img id="mscloud_1" src="/art/ms/cloud_1.png" />
          <img id="mscloud_2" src="/art/ms/cloud_2.png" />
          <img id="mscloud_3" src="/art/ms/cloud_3.png" />
          <img id="mscloud_4" src="/art/ms/cloud_4.png" />
        </div>
      </div>{" "}
      <div id="trigger_"></div>
      {/*  <!-- FIRST SENTENCE --> */}
      <div style={{ height: "300px" }}></div>
      <div id="trigger1"></div>
      <div id="pin1" className="" style={{ height: "600px" }}>
        <div className="s1">
          <h1 className="">i woke up to a peculiar stranger</h1>
        </div>
      </div>
      <div id="trigger_"></div>
      <div id="trigger_"></div>
      {/*  <!-- SECOND SENTENCE --> */}
      <div id="trigger2"></div>
      <div id="pin2" className="" style={{ height: "600px" }}>
        <div className="s2">
          <h1>who was that strange creature? was it real or not?</h1>
        </div>
      </div>
      <div style={{ height: "600px" }}></div>
      {/*  <!-- THIRD SENTENCE --> */}
      <div id="trigger3"></div>
      <div id="pin3" className="" style={{ height: "600px" }}>
        <img src="/art/lyingdown.png" style={{ left: "10%" }} />
        <div className="s3">
          <h1>...</h1>
          <h2 id="s3_2">
            "hey wake up, we gOtta get Out of here, the beast is cOming"
          </h2>
        </div>
      </div>
      <div style={{ height: "300px" }}></div>
      {/*  <!-- FOURTH SENTENCE --> */}
      <div id="trigger4"></div>
      <div id="pin4" className="" style={{ height: "600px" }}>
        <div className="s4">
          <h1>"my leg... I cAn't move"</h1>
          <h2>"dOn't worry, i'll carry yOu :)"</h2>
        </div>
      </div>
      ){/*  <!-- Fire --> */}
      <div id="triggerfire"></div>
      <div id="pinfire" className="center">
        <div id="sfire" className="sfire">
          <img id="imgfire" src="/art/fire/f1.png" />
        </div>
      </div>
      <div id="triggerfire2"></div>
      {/*  <!-- FIFTH SENTENCE --> */}
      <div id="trigger5"></div>
      <div id="pin5" className="" style={{ height: "600px" }}>
        <div className="s5">
          <h1>We travelled to the great bonfire</h1>

          <h2>As long as it keeps burning, it keeps us safe from the beast</h2>
        </div>
      </div>
      {/*  <!-- SIXTH SENTENCE --> */}
      <div id="trigger6"></div>
      <div id="pin6" className="" style={{ height: "600px" }}>
        <div className="s6">
          <h1>"you know, </h1>

          <h2>my fAther mAde this bonfire."</h2>
        </div>
      </div>
      {/*  <!-- SEVENTH SENTENCE --> */}
      <div id="trigger7"></div>
      <div id="pin7" className="" style={{ height: "600px" }}>
        <div className="s7">
          <h1>time passed. i fell asleep with the stranger along side</h1>
        </div>
      </div>
      {/*  <!-- EIGTH SENTENCE --> */}
      <div id="trigger8"></div>
      <div id="pin8" className="" style={{ height: "600px" }}>
        <div className="s8">
          <h1>...</h1>
        </div>
      </div>
      {/*  <!-- NINTH SENTENCE --> */}
      <div id="trigger9"></div>
      <div id="pin9" className="" style={{ height: "600px" }}>
        <div className="s9">
          <h1>I woke up from my slumber</h1>
          <h2>burning</h2>
        </div>
      </div>
      {/*  <!-- TENNISTH SENTENCE --> */}
      <div id="trigger10"></div>
      <div id="pin10" className="" style={{ height: "1200px" }}>
        <div className="s10">
          <h1>yOu</h1>
          <h2>...</h2>
          <h3>i helped yOu</h3>
          <h4>.............</h4>
        </div>
      </div>
      {/*  <!-- burn inverted Face scene --> */}
      <div id="triggerface"></div>
      <div id="pin11" className="">
        <div className="sbifs">
          <div id="bifnf"> </div>
          <div id="bifbf"> </div>
          <div id="bifif1" className="bifif">
            {" "}
          </div>
          <div id="bifif2" className="bifif">
            {" "}
          </div>
          <div id="bifif3" className="bifif">
            {" "}
          </div>
          <div id="bifif4" className="bifif">
            {" "}
          </div>
          <div id="bifmh1"> </div>
          <div id="bifmh2"> </div>
          <div id="bifg1"> </div>
          <div id="bifg2"> </div>
          <div id="bifg3">
            <img src="/art/g3.png" id="bifg3a" style={{ left: "0" }} />
          </div>
        </div>
      </div>
      <div id="triggerss"></div>
      <div id="pinending" className="center">
        <div id="sending" className="sending">
          <img id="ssbn" src="/art/ss/body.png" />
          <img id="ssfn" src="/art/ss/ssface1_2.png" />
          <img id="ssbi" src="/art/ss/body_iv.png" />
          <img id="ssf1" src="/art/ss/ssface2_iv2.png" />
          <img id="ssf2" src="/art/ss/ssface2_iv.png" />
          <img id="ssf3" src="/art/ss/ssface2_ivs.png" />
          <img id="ssh1" src="/art/ss/hand_iv.png" />
          <img id="ssh2" src="/art/ss/hand_iv2.png" />
          <img id="sg" src="/art/ss/gsmall.png" />
        </div>
      </div>
      <div id="trigger_"></div>
      <div id="trigger_"></div>
      <div style={{ height: "900px" }}></div>
      <div id="invertedmoon">
        <img src="/art/mooncirclesmallerinverted.png" />
        <h1 className="">
          thanks for reading | BTS blog post *coming soon* |{" "}
          <a href="https://yofrancisco.com" style={{ color: "inherit" }}>
            {" "}
            go back home {">>"}
          </a>
        </h1>
      </div>
    </div>
  </Layout>
)

export default IndexPage
